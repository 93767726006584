import PropTypes from "prop-types";

import { ImgixNextImage } from "@components/imgixNextImage";
import { SocialRow } from "@components/socialRow";
import { SectionHeading } from "@components/headings/section";

import { tagular } from "@cohesion/tagular";

import { LP_INSTAGRAM_URL } from "@constants/lpUrl";

function SocialBar({ socials }) {
  return (
    <div className="flex flex-col items-center md:flex-row">
      <h2 className="mb-2 text-sm font-semibold tracking-widest text-white uppercase md:mb-0 md:mr-3 lg:text-md">
        Follow ���Ĵ�ý:
      </h2>
      <SocialRow socials={socials} white negative />
    </div>
  );
}

SocialBar.propTypes = {
  socials: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
};

export function SocialMedia({
  promos,
  socials = [
    {
      href: "https://www.facebook.com/lonelyplanet/",
      label: "facebook",
    },
    {
      href: "https://www.instagram.com/lonelyplanet/",
      label: "instagram",
    },
    {
      href: "https://twitter.com/lonelyplanet/",
      label: "twitter",
    },
    {
      href: "https://www.tiktok.com/@lonelyplanet/",
      label: "tiktok",
    },
  ],
}) {
  return (
    <article className="flex flex-col">
      <div className="container flex items-center justify-center xl:justify-between">
        <SectionHeading className="text-white">
          <a
            className="no-underline hover:cursor-pointer"
            href={LP_INSTAGRAM_URL}
            onClick={() => {
              tagular("click", {
                actionOutcome: "EXTERNALLINK",
                outboundUrl: LP_INSTAGRAM_URL,
                webElement: {
                  location: "SECTION",
                  elementType: "TEXT",
                  position: "SOCIAL",
                  text: "#LONELPLANET",
                },
              });
            }}
          >
            #lonelyplanet
          </a>
        </SectionHeading>

        <div className="hidden xl:flex">
          <SocialBar socials={socials} />
        </div>
      </div>

      <div className="flex flex-wrap mt-8 lg:mt-16">
        {promos.map(({ userName, profileUrl, image: { url, alt } }) => (
          <a
            key={userName}
            href={profileUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="relative w-1/3 lg:flex-1 hover:cursor-pointer"
            onClick={() => {
              tagular("click", {
                actionOutcome: "EXTERNALLINK",
                outboundUrl: profileUrl,
                webElement: {
                  location: "CARDS",
                  elementType: "IMAGE",
                  position: "SOCIAL",
                  text: `CARD-${userName.toUpperCase()}`,
                },
              });
            }}
          >
            <div className="relative h-full">
              <ImgixNextImage
                className="w-full h-full"
                imgixParams={{ fit: "fill" }}
                width={350}
                height={400}
                src={url}
                alt={alt}
              />
              <div className="absolute bottom-0 flex items-end w-full h-full p-3 transition-opacity bg-gradient-to-t hover:opacity-0 from-transparent-60 via-transparent-20 to-transparent trans" />
              <span className="absolute bottom-0 p-3 font-semibold tracking-wide text-white break-all whitespace-normal text-tiny md:text-sm">{`@${userName}`}</span>
            </div>
          </a>
        ))}
      </div>

      <div className="flex items-center justify-center mt-8 xl:hidden lg:mt-0">
        <SocialBar socials={socials} />
      </div>
    </article>
  );
}

SocialMedia.propTypes = {
  promos: PropTypes.arrayOf(
    PropTypes.shape({
      userName: PropTypes.string,
      profileUrl: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
    })
  ).isRequired,
  socials: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};
